.App {
  text-align: center;
}

.slick-list> div {
  margin-left:0;
}

.slick-list {margin: 0 -5px;}
.slick-slide>div {padding-right:5px;}

.slick-prev:before,
.slick-next:before {
  color: #1D47B1 !important;
}

@media (prefers-color-scheme: dark) {
  .slick-prev:before,
  .slick-next:before {
    color: #1D47B1 !important;
  }
}
