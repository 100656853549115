@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  font-family: 'Poppins', sans-serif;
}

